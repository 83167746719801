import React, { Component } from "react";
import MealHeader from "../../meals/mealHeader";
import MobileHeader from "../../shared/mobile_header";
import { Button, FormGroup, Input, Fade } from "reactstrap";
import "./help_page.css";
import UserService from "../../../services/userService";
import { isBrowser, isMobile } from "react-device-detect";

/**
 * @component that let user send questions  ?
 */
class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subject: "",
      message: "",
      showTanksMessage: null,
      validSubject: false,
      validMessage: false,
      showError: false,
      errorMessage: "",
    };
  }

  /**
   * @function handelOnChange take the event of the fields when change and set the value to the state
   * @param {e}
   * @returns {void}
   */
  handelOnChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      validMessage: false,
      validSubject: false,
      showError: false,
    });
  };

  /**
   * @function submitForm if all fields is valid send the value of the form to the api and  show Thanks Message
   * @param {none}
   * @returns {void}
   */
  submitForm = async () => {
    const { validMessage, validSubject, subject, message } = this.state;
    if (subject && message) {
      if (!validMessage && !validSubject) {
        await UserService.help(subject.trim(), message.trim())
          .then((res) => {
            if (res) {
              this.setState({
                showTanksMessage: true,
              });
            }
          })
          .catch((err) => {
            this.setState({
              showError: true,
              errorMessage:
                "We are sorry. Something went wrong, please try again later.",
            });
          });
      } else {
        this.setState({
          showError: true,
          errorMessage:
            "We are sorry. Something went wrong, please try again later.",
        });
      }
    } else if (message === "" && subject === "") {
      this.setState({
        validMessage: true,
        validSubject: true,
      });
    } else if (message === "" && subject !== "") {
      this.setState({
        validMessage: true,
      });
    } else if (subject === "" && message !== "") {
      this.setState({
        validSubject: true,
      });
    }
  };

  render() {
    if (isBrowser) {
      return (
        <div>
          <MealHeader title={"Help Center"} />
          <div className="gray notification-web">
            <form
              className="helpForm"
              style={{
                display: this.state.showTanksMessage ? "none" : "block",
              }}
            >
              <h3 id="titleForm">How can we help you?</h3>

              <FormGroup className="sign-up-form">
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject "
                  onChange={this.handelOnChange}
                  invalid={this.state.validSubject}
                  maxLength="100"
                />
              </FormGroup>
              <FormGroup>
                {this.state.validSubject && (
                  <label
                    style={{
                      color: "red",
                    }}
                  >
                    This field is required
                  </label>
                )}
              </FormGroup>
              <FormGroup className="sign-up-form">
                <Input
                  className="textArea"
                  type="textarea"
                  name="message"
                  id="message"
                  placeholder="Type your message here"
                  onChange={this.handelOnChange}
                  invalid={this.state.validMessage}
                  maxLength="1000"
                />
              </FormGroup>
              <FormGroup>
                {this.state.validMessage && (
                  <label
                    style={{
                      color: "red",
                    }}
                  >
                    This field is required
                  </label>
                )}
              </FormGroup>
              <FormGroup>
                {this.state.showError && (
                  <label
                    style={{
                      color: "red",
                    }}
                  >
                    {this.state.errorMessage}
                  </label>
                )}
              </FormGroup>
              <Button color="danger" onClick={this.submitForm}>
                Submit
              </Button>
            </form>
            <Fade
              in={this.state.showTanksMessage}
              tag="h5"
              className="helpForm"
            >
              <h3 id="titleForm">
                Thank you,
                <br /> We received your message, and will get back to you very
                soon.
              </h3>
            </Fade>
          </div>
        </div>
      );
    }
    if (isMobile) {
      return (
        <div>
          <MobileHeader title="Help" link="/dailymenu" />

          <form
            className="helpForm"
            style={{
              display: this.state.showTanksMessage ? "none" : "block",
            }}
          >
            <h3 id="titleForm">How can we help you?</h3>

            <FormGroup className="sign-up-form">
              <Input
                type="text"
                name="subject"
                id="subject"
                placeholder="Subject "
                onChange={this.handelOnChange}
                invalid={this.state.validSubject}
                maxLength="100"
              />
            </FormGroup>

            <FormGroup>
              {this.state.validSubject && (
                <label
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </label>
              )}
            </FormGroup>
            <FormGroup className="sign-up-form">
              <Input
                className="textArea"
                type="textarea"
                name="message"
                id="message"
                placeholder="Type your message here"
                onChange={this.handelOnChange}
                invalid={this.state.validMessage}
                maxLength="1000"
              />
            </FormGroup>
            <FormGroup>
              {this.state.validMessage && (
                <label
                  style={{
                    color: "red",
                  }}
                >
                  This field is required
                </label>
              )}
            </FormGroup>
            <FormGroup>
              {this.state.showError && (
                <label
                  style={{
                    color: "red",
                  }}
                >
                  {this.state.errorMessage}
                </label>
              )}
            </FormGroup>
            <Button color="danger" onClick={this.submitForm}>
              Submit
            </Button>
          </form>
          <Fade in={this.state.showTanksMessage} tag="h5" className="helpForm">
            <h3 id="titleForm">
              Thank you,
              <br /> We received your message, and will get back to you very
              soon.
            </h3>
          </Fade>
        </div>
      );
    }
  }
}
export default Help;
