import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createHashHistory } from "history";
import { isBrowser, isMobile } from "react-device-detect";

import { UnauthorizedError } from "./services/errors";
import UserService from "./services/userService";

import Home from "./components/home";
import LogIn from "./components/account/login";
import ForgotPassword from "./components/account/forgot_password";
import SignUp from "./components/account/signup";
import VerifyPhone from "./components/account/verify_phone";
import VerificationCode from "./components/account/verification_code";
import DailyMenu from "./components/meals/daily_menu";
import SelectCompany from "./components/companies/select_company";
import RequestCompany from "./components/companies/request_company";
import ResetPassword from "./components/account/reset_password";
import WatingPage from "./components/account/waiting_page";
import ProfilePage from "./components/profile/profile_page";
import ChangePassword from "./components/profile/change_password"
import MyProfile from "./components/profile/my_profile";
import MyOrders from "./components/profile/my_orders";
import OrderInfo from "./components/profile/order_info";
import NotificationSettings from "./components/profile/notification_settings";
import Spinner from "./components/shared/spinner";
import Toast from "./components/shared/toast";
import Payment from "./components/profile/payment_method";
import RefferalCode from "./components/profile/refferal_code";
import ErrorMobile from "./components/shared/errorMobile";
import About_us from "./components/profile/about_us";
import logService from "./services/logService";
import AddCardForm from "./components/profile/add_new_card";
import Help from "./components/profile/help_page/help_page";
import SearchAddress from "./components/account/address/search_address/search_address";
import ResultAddress from "./components/account/address/result_address/result_address";
import Thanks from "./components/account/address/thanks_message/thanks_message";
import { getZlToken } from "./components/shared/common"
import UserAddresses from "./components/companies/userAddresses";
import loginRouteAuth from "./components/shared/loginRouteAuth";

const history = createHashHistory({ alwaysEnableState: true });
let lastErrorMessage = "";
class App extends Component {
  UNSAFE_componentWillMount() {
    let userData = getZlToken(localStorage.getItem("ZlToken"));
    window.addEventListener("error", function (e) {
      try {
        if (lastErrorMessage !== e.error.message) {
          lastErrorMessage = e.error.message;
          let userid = userData.id;
          let message =
            "UI Error occurred for user:" +
            userid +
            " error message: " +
            lastErrorMessage;
          logService.log(message);
        } else {
          lastErrorMessage = ""; //react throws the error two times, so this variable helps us avoid logging duplicate issue
        }
      } catch (error) {
        //do nothing
      }
      return false;
    });

    window.onunhandledrejection = (err) => {
      if (err.reason instanceof UnauthorizedError) {
        let x = window.location.href.match(/\/(\w+)$/)[1];
        UserService.logout();
        localStorage.clear();
        window.location.replace(`#/login?prev=${x}`);
      }
    };
    let url = window.location.href.toLowerCase();
    if (url.indexOf("http") === 0 && url.indexOf("localhost") < 0) {
      //if using browser not mobile application
      if (window.location.protocol !== "https:") {
        //if url is not secure then switch to secure
        window.location.href = window.location.href.replace("http", "https");
      }
    }
  }

  render() {
    if (isBrowser) {
      return (
        <Router history={history}>
          <Spinner />
          <Toast />
          <div>
            <Switch>
              <Route exact path="/" component={SearchAddress} />
              <Route path="/login" component={LogIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/verifyphone" component={VerifyPhone} />
              <Route path="/verificationcode" component={VerificationCode} />
              <Route path="/waitingpage" component={WatingPage} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route path="/dailymenu" component={loginRouteAuth(DailyMenu)} />
              <Route path="/useraddresses" component={loginRouteAuth(UserAddresses)} />
              <Route path="/selectcompany" component={loginRouteAuth(SelectCompany)} />
              <Route path="/requestcompany" component={loginRouteAuth(RequestCompany)} />
              <Route path="/profilepage" component={loginRouteAuth(ProfilePage)} />
              <Route path="/ChangePassword" component={loginRouteAuth(ChangePassword)} />
              <Route path="/myprofile" component={loginRouteAuth(MyProfile)} />
              <Route path="/myorders" component={loginRouteAuth(MyOrders)} />
              <Route path="/orderInfo" component={loginRouteAuth(OrderInfo)} />
              <Route path="/payment" component={loginRouteAuth(Payment)} />
              <Route path="/refferalcode" component={loginRouteAuth(RefferalCode)} />
              <Route path="/about_us" component={loginRouteAuth(About_us)} />
              <Route path="/add_new_card" component={loginRouteAuth(AddCardForm)} />
              <Route path="/help" component={loginRouteAuth(Help)} />

              <Route path="/notificationsettings" component={loginRouteAuth(NotificationSettings)} />
              <Route path="/searchaddress" component={SearchAddress} />
              <Route path="/resultaddress" component={ResultAddress} />
              <Route path="/thanks" component={Thanks} />
              <Redirect to="/" />
            </Switch>
          </div>
        </Router>
      );
    }
    if (isMobile) {
      return (
        <Router history={history}>
          <Spinner />
          <ErrorMobile />
          <div id="main-dev">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={LogIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/forgotpassword" component={ForgotPassword} />
              <Route path="/verifyphone" component={VerifyPhone} />
              <Route path="/verificationcode" component={VerificationCode} />
              <Route path="/waitingpage" component={WatingPage} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route path="/dailymenu" component={loginRouteAuth(DailyMenu)} />
              <Route path="/useraddresses" component={loginRouteAuth(UserAddresses)} />
              <Route path="/selectcompany" component={loginRouteAuth(SelectCompany)} />
              <Route path="/requestcompany" component={loginRouteAuth(RequestCompany)} />
              <Route path="/profilepage" component={loginRouteAuth(ProfilePage)} />
              <Route path="/ChangePassword" component={loginRouteAuth(ChangePassword)} />
              <Route path="/myprofile" component={loginRouteAuth(MyProfile)} />
              <Route path="/myorders" component={loginRouteAuth(MyOrders)} />
              <Route path="/orderInfo" component={loginRouteAuth(OrderInfo)} />
              <Route path="/payment" component={loginRouteAuth(Payment)} />
              <Route path="/refferalcode" component={loginRouteAuth(RefferalCode)} />
              <Route path="/about_us" component={loginRouteAuth(About_us)} />
              <Route path="/add_new_card" component={loginRouteAuth(AddCardForm)} />
              <Route path="/help" component={loginRouteAuth(Help)} />

              <Route
                path="/notificationsettings"
                component={loginRouteAuth(NotificationSettings)}
              />
              <Route path="/searchaddress" component={SearchAddress} />
              <Route path="/resultaddress" component={ResultAddress} />
              <Route path="/thanks" component={Thanks} />
              <Redirect to="/login" />
            </Switch >
          </div >
        </Router >
      );
    }
  }
}

export default App;
